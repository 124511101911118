import { graphql, Link, PageProps } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import Layout from "../component/layout/layout"
import { pageContainer } from "../styles.css"
import {
  container,
  linkPrimaryAbout,
  pictureContainer,
} from "../styles/about.css"

type Props = {}

type DataType = {
  about: {
    image: {
      title: string
      gatsbyImageData: IGatsbyImageData
    }
    content: {
      childMarkdownRemark: {
        html: string
      }
    }
  }
}

const about = ({ data: { about } }: PageProps<DataType>) => {
  const image = getImage(about.image.gatsbyImageData)

  if (!image) return null

  console.log(about.content.childMarkdownRemark)

  return (
    <Layout>
      <div className={pageContainer}>
        <div className={container}>
          <div className={pictureContainer}>
            <GatsbyImage image={image} alt={about.image.title}></GatsbyImage>
          </div>
          <div>
            <p>
              Je m'appelle Patrick Clichy je suis artiste peintre dans le
              département de la Drôme
            </p>
            <br />
            <p>
              Je me suis intéressé depuis de nombreuses années à la technique du
              couteau en peinture à l'huile. Je suis autodidacte et amoureux
              inconditionnel de la nature.
            </p>
            <p>
              De par ma profession qui était celle de vétérinaire, j'ai eu tout
              au long de ma vie personnelle et professionnelle un contact étroit
              avec cette nature et les éléments qui la composent.
            </p>
            <p>
              Je veux exprimer ainsi par la peinture au couteau une vision
              personnelle de cette nature qui nous entoure et que nous devons
              préserver.
            </p>
            <br />
            <p>
              Ma recherche en peinture est avant tout celle d'une harmonie des
              couleurs , qui sont parfois effectivement éloignées de la réalité
              dans mes toiles mais également la recherche avec une part
              d'improvisation de suggestions visuelles magnifiquement autorisées
              par la technique du couteau. Mes supports sont des photos glanées
              lors de mes randonnées mais aussi des images que j'ai retenues et
              que je retransmets en atelier sur ma toile.
            </p>
            <br />
            <p>
              Je finirai par cette citation d'Eugène Delacroix "L'exécution dans
              la peinture doit toujours tenir compte de l'improvisation"
            </p>
            <br />
            <p>Patrick Clichy</p>
            <Link to="/galerie" className={linkPrimaryAbout}>
              Découvrez mes oeuvres dans la galerie
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default about

export const query = graphql`
  {
    about: contentfulAPropos {
      image {
        title
        gatsbyImageData
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
